.about-us {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  background-color: #f4f4f4;
}

.about-us-content {
  display: flex;
  flex-wrap: wrap;
  max-width: 80%;
  width: 100%;
  align-items: center;
  position: relative;
}

.about-us-image-container {
  flex: 1;
  max-width: 600px;
  padding: 20px;
  position: relative;
}

.about-us-image {
  width: 80%;
  height: 415px;
  mask-image: url('../components/images/mask.png'); /* Use the actual path to your mask image */
  mask-size: cover;
  mask-repeat: no-repeat;
  -webkit-mask-image: url('../components/images/mask.png'); /* For Safari */
  -webkit-mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  z-index: 1;
  position: relative;
}

.background-shape {
  position: absolute;
  top: 0;
  left: -4%;
  width: 70%;
  height: 90%;
  background: #ff6347;
  mask-image: url('../components/images/mask.png'); /* Use the actual path to your mask image */
  mask-size: cover;
  mask-repeat: no-repeat;
  -webkit-mask-image: url('../components/images/mask.png'); /* For Safari */
  -webkit-mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  z-index: 0;
  animation: moveShape 2s infinite linear;
  transform: rotate(70deg); /* Adjust the degree value to achieve the desired rotation */
}

@keyframes moveShape {
  0% {
    transform: translateX(0) translateY(0) rotate(45deg);
  }
  50% {
    transform: translateX(10px) translateY(10px) rotate(45deg);
  }
  100% {
    transform: translateX(0) translateY(0) rotate(45deg);
  }
}

.about-us-text {
  flex: 1;
  max-width: 600px;
  padding: 20px;
}

.about-us-text h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.about-us-text p {
  font-size: 16px;
  margin-bottom: 20px;
}

.features-list {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.features-list li {
  font-size: 16px;
  margin-bottom: 10px;
  position: relative;
  padding-left: 30px;
  box-shadow: 0px 25px 70px rgb(8 10 55 / 12%);
}

.features-list li::before {
  content: "✔";
  position: absolute;
  left: 0;
  top: 0;
  color: orange;
}

.cta-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: #ff6347;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #ff4500;
}
.cta-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  font-size: 1.2rem;
  color: white;
  background-color: #ff6347;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
  border: none; /* Ensure no border */
  cursor: pointer; /* Pointer cursor */
}

.cta-button:hover {
  background-color: #ff4500;
}
/* Responsive Styles */
@media (max-width: 1024px) {
  .about-us-content {
    flex-direction: column;
    align-items: center;
  }

  .about-us-image-container,
  .about-us-text {
    max-width: 100%;
  }

  .about-us-image-container {
    padding: 10px;
  }

  .about-us-text h1 {
    font-size: 32px;
  }

  .about-us-text p,
  .features-list li {
    font-size: 14px;
  }

  .about-us-image {
    width: 70%;
    height: 365px;
  }
  .background-shape{
    width: 65%;
  }
}

@media (max-width: 767px) {
  .about-us-content {
    flex-direction: column;
    align-items: center;
  }

  .about-us-image-container,
  .about-us-text {
    max-width: 100%;
  }

  .about-us-image-container {
    padding: 5px;
  }

  .about-us-text h1 {
    font-size: 28px;
  }

  .about-us-text p,
  .features-list li {
    font-size: 12px;
  }

  .about-us-image {
    width: 100%;
    height: 285px;
  }
  .background-shape{
    width: 100%;
    height: 99%;
  }
}

@media (max-width: 480px) {
  .about-us-content {
    flex-direction: column;
    align-items: center;
  }

  .about-us-image-container,
  .about-us-text {
    max-width: 100%;
  }

  .about-us-image-container {
    padding: 2px;
  }

  .about-us-text h1 {
    font-size: 24px;
  }

  .about-us-text p,
  .features-list li {
    font-size: 14px;
  }

  .about-us-image {
    width: 100%;
    height: 285px;
  }
  .background-shape{
    width: 100%;
    height: 99%;
  }
}
