/* General Styles */
.about-container {
    font-family: Arial, sans-serif;
    margin-top: 5rem;
}

/* Hero Section */
.hero-section {
    background-color: #f4f4f4;
    text-align: center;
}

.hero-section h1 {
    font-size: 3rem;
    margin: 0;
}

.hero-section p {
    font-size: 1em;
    color: #ffffff;
}

/* Company Information Section */
.company-info-section {
    padding: 4rem 0;
    max-width: 1200px;
    margin: 0 auto;
}

.company-info-section h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.company-info-section p {
    font-size: 1.2rem;
    color: #666;
}

/* Bullet Points with Check Icons */
.company-info-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.company-info-section li {
    position: relative;
    padding-left: 2.5rem;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    color: #666;
}

.company-info-section li::before {
    content: '\f00c'; /* Font Awesome check icon */
    font-family: 'Font Awesome 5 Free'; /* Ensure Font Awesome is included in your project */
    font-weight: 900;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.5rem;
    color: #ff6347;
}

/* Mission and Vision Section */
.mission-vision-section {
    padding: 4rem 0;
    background-color: #eef2f5;
}

.mission-vision-content {
    max-width: 1200px;
    margin: 0 auto;
}

.mission-vision-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.mission,
.vision {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.mission img,
.vision img {
    width: 40%;
    height: auto;
    transition: transform 0.3s ease, filter 0.3s ease;
    position: relative;
}

.mission img:hover,
.vision img:hover {
    transform: translateY(-5px);
    filter: brightness(0.9);
}

.mission-content,
.vision-content {
    text-align: center;
}

.mission h3,
.vision h3 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.mission p,
.vision p {
    font-size: 1.2rem;
    color: #666;
}

/* Why Choose Us Section */
.why-choose-us-section {
    padding: 4rem 0;
    max-width: 1200px;
    margin: 0 auto;
}

.why-choose-us-section h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    text-align: center;
}

.choose-us-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}

.choose-us-item {
    position: relative;
    flex: 1;
    min-width: 280px;
    background-color: #fff;
    padding: 2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.choose-us-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ff6347;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease;
    z-index: 0;
}

.choose-us-item h3,
.choose-us-item p {
    position: relative;
    z-index: 1;
}

.choose-us-item:hover::before {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.choose-us-item .icon-container {
    font-size: 3rem;
    color: #ff6347;
    margin-bottom: 1rem;
}

.choose-us-item h3 {
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
    transition: color 0.3s ease;
}

.choose-us-item p {
    font-size: 1.2rem;
    color: #666;
    transition: color 0.3s ease;
}

.choose-us-item:hover {
    transform: translateY(-5px);
}

.choose-us-item:hover h3,
.choose-us-item:hover p {
    color: #fff;
}

/* Contact Form Section */
.contact-form-section {
    padding: 4rem 0;
    background-color: #f4f4f4;
}

.contact-form-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.contact-form h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.contact-form input,
.contact-form textarea {
    padding: 1rem;
    font-size: 1.2rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.contact-form textarea {
    resize: none; /* Prevents resizing of the textarea */
}

.contact-form button {
    padding: 1rem;
    font-size: 1.2rem;
    background-color: #ff6347;
    color: #fff;
    border: none;
    cursor: pointer;
}

/* Responsive Design for Tablet and Mobile */
@media (max-width: 1024px) {
    .mission,
    .vision {
        flex-direction: column;
    }

    .mission img,
    .vision img {
        width: 100%;
    }

    .choose-us-wrapper {
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .mission img,
    .vision img {
        width: 60%;
    }

    .mission-content,
    .vision-content {
        text-align: center;
    }

    .choose-us-item {
        min-width: 80%;
    }
    .company-info-section{
        padding: 10px;
        margin: 15px;
    }
    .mission p, .vision p{
        font-size: 1rem;
    }
    .contact-form-content{
        margin: 15px;
    }
}

@media (max-width: 475px) {
    .hero-section h1 {
        font-size: 2rem;
    }

    .hero-section p {
        font-size: 1.2rem;
    }

    .company-info-section h2,
    .why-choose-us-section h2,
    .contact-form h2 {
        font-size: 2rem;
    }

    .choose-us-wrapper {
        flex-direction: column;
    }

    .mission h3,
    .vision h3 {
        font-size: 1.5rem;
    }

    .choose-us-item h3 {
        font-size: 1.5rem;
    }

    .choose-us-item p {
        font-size: 1rem;
    }
    .company-info-section{
        padding: 10px;
        margin: 15px;
    }
    .mission p, .vision p{
        font-size: 1rem;
    }
    .contact-form-content{
        margin: 15px;
    }
}
