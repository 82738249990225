.skills-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
    background-color: #f4f4f4;
  }
  
  .skills-content {
    display: flex;
    flex-wrap: wrap;
    max-width: 80%;
    width: 100%;
    align-items: center;
    position: relative;
    flex-direction: row-reverse; /* Added this line to swap positions */
  }
  
  .skills-image-container {
    flex: 1;
    max-width: 600px;
    padding: 20px;
    position: relative;
  }
  
  .skills-image {
    width: 80%;
    height: 415px;
    mask-image: url('../components/images/mask.png'); /* Use the actual path to your mask image */
    mask-size: cover;
    mask-repeat: no-repeat;
    -webkit-mask-image: url('../components/images/mask.png'); /* For Safari */
    -webkit-mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    z-index: 1;
    position: relative;
  }
  
  .skills-background-shape {
    position: absolute;
    top: 0;
    left: -4%;
    width: 70%;
    height: 90%;
    background: #ff6347;
    mask-image: url('../components/images/mask.png'); /* Use the actual path to your mask image */
    mask-size: cover;
    mask-repeat: no-repeat;
    -webkit-mask-image: url('../components/images/mask.png'); /* For Safari */
    -webkit-mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    z-index: 0;
    animation: moveShape 2s infinite linear;
    transform: rotate(70deg); /* Adjust the degree value to achieve the desired rotation */
  }
  
  @keyframes moveShape {
    0% {
      transform: translateX(0) translateY(0) rotate(45deg);
    }
    50% {
      transform: translateX(10px) translateY(10px) rotate(45deg);
    }
    100% {
      transform: translateX(0) translateY(0) rotate(45deg);
    }
  }
  
  .skills-text {
    flex: 1;
    max-width: 600px;
    padding: 20px;
  }
  
  .skills-text h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .skills-text p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .skills-features-list {
    list-style: none;
    padding: 0;
    margin: 20px 0;
  }
  
  .skills-features-list li {
    font-size: 16px;
    margin-bottom: 10px;
    position: relative;
    padding-left: 30px;
    box-shadow: 0px 25px 70px rgb(8 10 55 / 12%);
  }
  
  .skills-features-list li::before {
    content: "✔";
    position: absolute;
    left: 0;
    top: 0;
    color: #ff6347;
  }
  
  .skills-cta-button {
    display: inline-block;
    padding: 15px 30px;
    background-color: #ff6347;
    color: #fff;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .skills-cta-button:hover {
    background-color: #ff4500;
  }
  .skills-cta-button {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    font-size: 1.2rem;
    color: white;
    background-color: #ff6347; /* Match the theme color */
    text-decoration: none; /* Remove underline */
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
    border: none; /* Ensure no border */
    cursor: pointer; /* Pointer cursor */
  }
  
  .skills-cta-button:hover {
    background-color: #ff4500; /* Darker shade on hover */
  }
  /* Responsive Styles */
  @media (max-width: 1024px) {
    .skills-content {
      flex-direction: column;
      align-items: center;
    }
  
    .skills-image-container,
    .skills-text {
      max-width: 100%;
    }
  
    .skills-image-container {
      padding: 10px;
    }
  
    .skills-text h1 {
      font-size: 32px;
    }
  
    .skills-text p,
    .skills-features-list li {
      font-size: 14px;
    }
  
    .skills-image {
      width: 70%;
      height: 365px;
    }
  
    .skills-background-shape {
      width: 65%;
    }
  }
  
  @media (max-width: 767px) {
    .skills-content {
      flex-direction: column;
      align-items: center;
    }
  
    .skills-image-container,
    .skills-text {
      max-width: 100%;
    }
  
    .skills-image-container {
      padding: 5px;
    }
  
    .skills-text h1 {
      font-size: 28px;
    }
  
    .skills-text p,
    .skills-features-list li {
      font-size: 14px;
    }
  
    .skills-image {
      width: 100%;
      height: 285px;
    }
  
    .skills-background-shape {
      width: 100%;
      height: 99%;
    }
  }
  
  @media (max-width: 480px) {
    .skills-content {
      flex-direction: columns;
      align-items: center;
    }
  
    .skills-image-container,
    .skills-text {
      max-width: 100%;
    }
  
    .skills-image-container {
      padding: 2px;
    }
  
    .skills-text h1 {
      font-size: 24px;
    }
  
    .skills-text p,
    .skills-features-list li {
      font-size: 14px;
    }
  
    .skills-image {
      width: 100%;
      height: 285px;
    }
  
    .skills-background-shape {
      width: 100%;
      height: 99%;
    }
  }
  