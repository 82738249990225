/* General Styles */

.header {
    background: linear-gradient(to right, #ff4500,  #e0b9ab);
    color: white;
    padding: 40px;
    text-align: center;
    animation: fadeInDown 1s;
    margin-top: 4rem;
}
.success-message{
    text-align: center;
}
.header-content h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
}

.header-content p {
    font-size: 1em;
}

.contact-info {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 40px 0;
    animation: fadeInUp 1s;
}

.info-item {
    flex: 1;
    margin: 10px;
    text-align: center;
    transition: transform 0.3s ease;
}

.info-item:hover {
    transform: scale(1.05);
}

.info-item img {
    width: 80px;
    margin-bottom: 20px;
}

.info-item h2 {
    margin-bottom: 10px;
}

.contact-form-section {
    background-color: #f9f9f9;
    padding: 40px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1s;
}

.contact-form-section h2 {
    text-align: center;
    margin-bottom: 20px;
}

.contact-form {
    max-width: 600px;
    margin: 0 auto;
}

.form-group {
    margin-bottom: 0;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.submit-btn {
    background-color: #ff4500;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
}

.submit-btn:hover {
    background-color: #ea7347;
}

.faq-section {
    margin: 40px 0;
}

.faq-section h2 {
    text-align: center;
    margin-bottom: 20px;
}

.faqs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0 60px;
}

.faq-column {
    flex: 1;
    min-width: 300px;
}

.faq-item {
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.faq-question {
    background-color: #ededed;
    color: rgb(21, 21, 21);
    padding: 15px;
    text-align: left;
    width: 100%;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1.1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease;
}

.faq-question:hover {
    background-color: #efc1b0;
}

.faq-arrow {
    width: 10px;
    height: 10px;
    border-right: 2px solid #222;
    border-bottom: 2px solid #ff4500;
    transform: rotate(45deg);
    transition: transform 0.3s ease;
}

.faq-arrow.open {
    transform: rotate(-135deg);
}

.faq-answer {
    display: none;
    padding: 15px;
    background-color: #f9f9f9;
}

.faq-answer.show {
    display: block;
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Media Queries for Responsiveness */

/* Tablet Devices */
@media (max-width: 1024px) {
    .header-content h1 {
        font-size: 2em;
    }

    .header-content p {
        font-size: 1em;
    }

    .contact-info {
        margin: 30px 0;
    }

    .faq-section {
        margin: 30px 0;
    }

    .faqs {
        padding: 0 40px;
    }

    .faq-column {
        min-width: 250px;
    }
}

/* Mobile Devices */
@media (max-width: 768px) {
    .header-content h1 {
        font-size: 1.5em;
    }

    .header-content p {
        font-size: 0.9em;
    }

    .contact-info {
        flex-direction: column;
        align-items: center;
    }

    .info-item {
        margin: 20px 0;
    }

    .contact-form-section {
        padding: 20px 10px;
    }

    .contact-form {
        width: 100%;
    }

    .faqs {
        padding: 0 20px;
        gap: 10px;
    }

    .faq-column {
        min-width: 100%;
    }
    .form-group input, .form-group textarea{
        width: 90%;
    }
}
