.technologies {
  position: relative;
  padding: 40px 20px;
  text-align: center;
  background-color: #f9f9f9;
}

.technologies-title {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
}

.technologies-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.technology-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 200px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.technology-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.technology-card-icon {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-bottom: 15px;
}

.technology-card-title {
  font-size: 1.2rem;
  color: #555;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .technology-card-icon {
    width: 60px;
    height: 60px;
  }

  .technology-card-title {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .technologies-title {
    font-size: 1.8rem;
  }

  .technology-card-icon {
    width: 50px;
    height: 50px;
  }

  .technology-card-title {
    font-size: 0.9rem;
  }
}
