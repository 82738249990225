/* Base styles */
.services-section {
  padding: 2rem;
  background-color: #f4f4f4;
}

.services-title {
  text-align: center;
  font-size: 2rem;
  color: #333;
  margin-bottom: 2rem;
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.service-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 1rem;
  margin: 1rem;
  text-align: center;
  max-width: 300px;
  position: relative;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
}

.service-icon {
  width: 100px;
  height: 100px;
  transition: transform 0.6s ease;
}

.service-card:hover .service-icon {
  transform: rotateY(360deg);
}

.service-content {
  padding: 1rem;
}

.service-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
  transition: color 0.3s ease;
}

.service-card:hover .service-title {
  color: #ff6347; /* CTA button color on hover */
}

.service-info {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1rem;
}

.service-demand {
  width: 100%;
  background: #f4f4f4;
  border-radius: 4px;
  height: 10px;
  position: relative;
  overflow: hidden; /* Ensures the animation stays within the bar */
  margin-bottom: 0.5rem; /* Space between the bar and text */
}

.demand-bar {
  height: 100%;
  background: #ff6347; /* CTA button color */
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  animation: fillBar 1s ease-out; /* Animation duration */
}

.demand-text-container {
  margin-top: 0.5rem; /* Space between demand bar and text */
}

.demand-text {
  font-size: 0.875rem;
  color: #333;
}

/* Animation for demand bar */
@keyframes fillBar {
  from {
      width: 0;
  }
  to {
      width: 100%;
  }
}

/* Responsive styles */
@media (max-width: 1024px) and (min-width: 768px) {
  .services-container {
      /* Show 2 service cards per row */
      flex-direction: row;
      gap: 1rem; /* Adjust gap between cards if needed */
  }
  .service-card {
      flex: 0 1 calc(50% - 2rem); /* 50% width minus margin */
  }
}

@media (max-width: 768px) {
  .service-title {
      font-size: 1.25rem;
  }

  .service-info {
      font-size: 0.875rem;
  }
}

@media (max-width: 480px) {
  .service-title {
      font-size: 1rem;
  }

  .service-info {
      font-size: 0.75rem;
  }

  .service-card {
      margin: 0.5rem;
  }
}
