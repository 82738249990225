/* Base styles */
.slide-text-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: #f4f4f4; /* White background color */
    padding: 10px 0 10px 0;
  }
  
  .slide-text-content {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
  }
  
  .slide-text {
    display: flex;
    flex-wrap: nowrap;
    gap: 2rem; /* Spacing between words */
    animation: slideText 30s linear infinite; /* Slower sliding speed */
  }
  
  /* Duplicate the text content for seamless scrolling */
  .slide-text::after {
    content: attr(data-content); /* Duplicate text content */
    display: flex;
    flex-wrap: nowrap;
    gap: 2rem; /* Spacing between words */
  }
  
  /* Styling for each span */
  .slide-text span {
    font-size: 4rem;
    color: #333333e6;
    font-weight: bold;
    white-space: nowrap;
    font-family: monospace;
  }
  
  /* Dot styling */
  .slide-text span::before {
    content: '• '; /* Dot before each word */
    color: #ff6347; /* Color of the dot */
    font-size: 4rem; /* Match dot size with font size */
    margin-right: 0.5rem; /* Space between dot and text */
  }
  
  /* Animation */
  @keyframes slideText {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  
  /* Responsive styles */
  @media (max-width: 1024px) {
    .slide-text span {
      font-size: 1.5rem; /* Smaller font size for tablets */
    }
  }
  
  @media (max-width: 768px) {
    .slide-text span {
      font-size: 2rem; /* Smaller font size for mobile devices */
    }
    .slide-text span::before{
        font-size: 2rem;
    }
  }
  