.cto-profile-page {
    font-family: Arial, sans-serif;
    color: #333;
}

.header {
    background: #007bff;
    color: white;
    text-align: center;
    padding: 40px 20px;
}

.about-cto {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
}

.cto-image img {
    width: 200px;
    border-radius: 50%;
    margin-right: 20px;
}

.cto-bio {
    max-width: 500px;
}

.experience-section, .skills-section, .vision-section, .contact-section, .faq-section {
    padding: 50px 20px;
    text-align: center;
}

.experience-container, .skills-container {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 20px;
}

.experience-item, .skills-item {
    text-align: center;
    max-width: 300px;
}

.faqs {
    max-width: 600px;
    margin: auto;
}

.faq-item {
    background: #f1f1f1;
    padding: 15px;
    margin-bottom: 10px;
    cursor: pointer;
}

.faq-answer {
    display: none;
}

.faq-answer.show {
    display: block;
}
