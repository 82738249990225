.auth-success-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .auth-title {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .auth-message {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  
  .redirect-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #0070f3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .redirect-button:hover {
    background-color: #005bb5;
  }
  