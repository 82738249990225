.pricing-plan {
    padding: 50px 20px;
    background-color: #f4f4f4;
}

.pricing-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.pricing-header {
    flex: 1;
    text-align: center;
    margin-bottom: 40px;
}

.pricing-header h2 {
    color: #000;
    font-size: 2.5rem;
}

.caption {
    color: #f96d00; /* Matches the Pro plan's background color */
    font-size: 1.2rem;
    margin-bottom: 10px;
    font-weight: bold;
}

.plans {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex: 1;
    flex-wrap: wrap;
}

.plan {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 20px;
    flex-basis: calc(33.333% - 20px); /* Adjusts for 3 cards */
    box-sizing: border-box;
    position: relative;
    transition: transform 0.3s;
    text-align: left;
    cursor: pointer;
}

.plan h3 {
    margin-bottom: 20px;
    font-size: 1.5em;
}

.plan ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
    text-align: left;
}

.plan ul li {
    margin: 10px 0;
    display: flex;
    align-items: center;
}

.plan ul li::before {
    content: '✔️';
    margin-right: 10px;
    font-size: 1.2em;
}

.plan .price {
    font-size: 24px;
    margin-bottom: 20px;
}

.plan .price span {
    display: inline-block;
    margin-right: 5px;
}

.plan button {
    background-color: #f96d00;
    border: none;
    border-radius: 4px;
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.plan button:hover {
    background-color: #ff4500;
}

.plan.popular {
    background: linear-gradient(135deg, #c55c0b, #fa926c);
    color: #fff;
}

.plan.popular h3,
.plan.popular ul,
.plan.popular .price,
.plan.popular button {
    color: #fff;
}

.ribbon {
    position: absolute;
    top: -10px;
    right: -10px;
    background: #f96d00;
    color: #fff;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: bold;
}

.plan.zoomed {
    transform: scale(1.1);
    z-index: 1;
}

/* Mobile (less than 500px) */
@media (max-width: 499px) {
    .pricing-container {
        flex-direction: column;
    }

    .plans {
        flex-direction: column;
        align-items: center;
    }

    .pricing-header {
        text-align: center;
        margin-bottom: 20px;
    }

    .plan {
        max-width: 90%;
    }
}

/* Tablet (500px to 1023px) */
@media (min-width: 500px) and (max-width: 1023px) {
    .pricing-container {
        flex-direction: column;
        align-items: center;
    }

    .pricing-header {
        text-align: center;
        margin-bottom: 20px;
        order: -1; /* Move the heading to the top */
    }

    .plans {
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .plan {
        flex-basis: 30%; /* Flex-basis for 3 cards in a row */
        max-width: calc(33.333% - 20px); /* Adjust card width */
        font-size: 0.9em; /* Reduce font size slightly */
        padding: 15px;
        margin: 5px;
    }

    .plan h3 {
        font-size: 1.3em;
        margin-bottom: 15px;
    }

    .plan ul li::before {
        font-size: 1em;
    }

    .plan .price {
        font-size: 20px;
    }

    .plan button {
        padding: 8px 15px;
    }
}

/* Desktop (1024px and above) */
@media (min-width: 1024px) {
    .pricing-container {
        flex-direction: row;
    }

    .pricing-header {
        text-align: center;
        margin-bottom: 0;
    }

    .plans {
        justify-content: space-around;
    }
}
