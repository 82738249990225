/* General Styles */
body {
    font-family: Arial, sans-serif;
}

h1, h2, h3 {
    margin: 0;
    padding: 10px;
}

h1 {
    font-size: 3rem;
}

img {
    max-width: 100%;
    height: auto;
}

/* Service Cards Section */
.service-cards-section {
    text-align: center;
}

.service-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.service-card1 {
    flex: 1 1 calc(25% - 40px); /* 4 cards per row on desktop */
    box-sizing: border-box;
    text-align: center;
    padding: 20px;
    margin: 20px;
    background-color: #ffffff; /* Card background color */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition effects */
    overflow: hidden; /* Prevents content from overflowing */
    position: relative; /* Positioning for potential overlay effects */
}

.service-card1:hover {
    transform: translateY(-10px); /* Slight lift effect on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* More pronounced shadow on hover */
}

.service-card1 img {
    width: 100%;
    max-width: 200px; /* Adjust size as needed */
    border-radius: 8px; /* Rounded corners for images */
    transition: transform 0.3s ease, opacity 0.3s ease; /* Smooth transition for image effects */
}

.service-card1:hover img {
    transform: scale(1.05); /* Slight zoom effect on hover */
    opacity: 0.9; /* Slight dimming effect on hover */
}

.service-card1 h3 {
    font-size: 1.5rem;
    margin: 15px 0 10px;
    color: #333; /* Title color */
    font-weight: 600;
    transition: color 0.3s ease; /* Smooth transition for title color */
}

.service-card1:hover h3 {
    color: #ff6347; /* Change title color on hover */
}

.service-card1 p {
    font-size: 1rem;
    color: #666; /* Description color */
    line-height: 1.5;
    margin: 10px 0;
}

.service-card1 .button {
    display: inline-block;
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #007bff; /* Button background color */
    color: #ffffff; /* Button text color */
    border-radius: 5px;
    text-decoration: none;
    font-size: 1rem;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for background color and button movement */
}

.service-card1 .button:hover {
    background-color: #0056b3; /* Darker background on hover */
    transform: scale(1.05); /* Slightly enlarge button on hover */
}

/* Optional overlay effect */
.service-card1::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.05); /* Subtle overlay */
    transition: background 0.3s ease;
}

.service-card1:hover::before {
    background: rgba(0, 0, 0, 0.1); /* Darker overlay on hover */
}

/* Responsive Styles for Service Cards */
@media (max-width: 1200px) {
    .service-card1 {
        flex: 1 1 calc(33.33% - 40px); /* 3 cards per row on large tablets */
    }
}

@media (max-width: 768px) {
    .service-card1 {
        flex: 1 1 calc(50% - 40px); /* 2 cards per row on tablets */
    }
}

@media (max-width: 480px) {
    .service-card1 {
        flex: 1 1 100%; /* 1 card per row on mobile */
    }
}

/* Gallery Section */
.gallery-section {
    padding: 20px;
    text-align: center;
    font-size: 1.5rem;
}

.gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.gallery-item {
    flex: 1 1 calc(33.33% - 40px); /* 3 images per row on large screens */
}

@media (max-width: 768px) {
    .gallery-item {
        flex: 1 1 calc(50% - 40px); /* 2 images per row on tablets */
    }
}

@media (max-width: 480px) {
    .gallery-item {
        flex: 1 1 100%; /* 1 image per row on mobile */
    }
}

/* Inquiry Section Styles */
.inquiry-section {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    gap: 20px;
}

.inquiry-left {
    flex: 0 0 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inquiry-left img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

.inquiry-right {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
}

.inquiry-right h2 {
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
}

.inquiry-right form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.inquiry-right input, 
.inquiry-right select,
.inquiry-right textarea {
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}

.inquiry-right input:focus, 
.inquiry-right select:focus, 
.inquiry-right textarea:focus {
    border-color: #007bff;
    outline: none;
}

.inquiry-right button {
    padding: 12px;
    background-color: #ff6347;
    color: #fff;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.inquiry-right button:hover {
    background-color: #eb5338;
}

.contact-field {
    display: flex;
    gap: 10px;
}

.contact-field select,
.contact-field input[type="text"] {
    flex: 1;
}

/* Why Choose Us Section Styles */
.why-choose-us-section {
    padding: 40px 20px;
    background-color: #fff;
    text-align: center;
}

.why-choose-us-section h2 {
    font-size: 32px;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
}

.why-choose-us-section .intro-text {
    font-size: 18px;
    color: #666;
    margin-bottom: 40px;
}

.reasons {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.reason-card {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    max-width: 300px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.reason-card h3 {
    font-size: 22px;
    color: #333;
    margin-bottom: 10px;
    font-weight: bold;
}

.reason-card p {
    font-size: 16px;
    color: #555;
}

/* Responsive Styles for Reason Cards */
@media (max-width: 768px) {
    .reason-card {
        max-width: 100%;
        padding: 15px;
    }
    .reasons{
        flex-direction: column;
        padding: 20px;
    }
    .inquiry-section {
        flex-direction: column;
    }
    .inquiry-right{
        width: 100%;
    }
}
