.our-process {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: #0b0d23;
    padding: 40px;
    color: #fff;
}

.process-step {
    text-align: center;
    max-width: 200px;
    margin-bottom: 20px;
}

.process-icon {
    position: relative;
    width: 80px;
    height: 80px;
    margin: 0 auto 10px;
}

.process-icon img {
    width: 70%;
    height: 70%;
    border-radius: 50%;
    margin-top: 10px;
}

.rotating-circle {
    position: absolute;
    top: 80%;
    left: 80%;
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
    transform-origin: -25px -25px;
    animation: rotate 3s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.orange {
    background-color: #f96d00;
    border-radius: 50%;
}

.blue {
    background-color: #006df9;
    border-radius: 50%;
}

.step-number {
    margin-top: 10px;
    padding: 5px 10px;
    border-radius: 20px;
}

.orange.step-number {
    background-color: #f96d00;
}

.blue.step-number {
    background-color: #006df9;
}

/* Media queries for responsiveness */
@media (max-width: 1020px) {
    .our-process {
        justify-content: center;
    }
    .process-step {
        flex: 1 1 45%;
        max-width: 45%;
    }
}

@media (max-width: 768px) {
    .our-process {
        flex-direction: row;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
        position: relative;
    }
    .process-step {
        flex: none;
        scroll-snap-align: center;
        width: 100%;
        white-space: normal;
        margin-bottom: 20px;
        font-size: 14px;
    }
    .step-number{
        margin: 10px;
    }
}
